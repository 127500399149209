import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9416efe0 = () => interopDefault(import('../src/pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _53ce9cf2 = () => interopDefault(import('../src/pages/authenticate.vue' /* webpackChunkName: "pages/authenticate" */))
const _e02e8fc4 = () => interopDefault(import('../src/pages/black-friday-2024.vue' /* webpackChunkName: "pages/black-friday-2024" */))
const _03de02f5 = () => interopDefault(import('../src/pages/business/index.vue' /* webpackChunkName: "pages/business/index" */))
const _3ad77932 = () => interopDefault(import('../src/pages/calculators/index.vue' /* webpackChunkName: "pages/calculators/index" */))
const _c588fa90 = () => interopDefault(import('../src/pages/career-track-certificate.vue' /* webpackChunkName: "pages/career-track-certificate" */))
const _6b869790 = () => interopDefault(import('../src/pages/career-tracks/index.vue' /* webpackChunkName: "pages/career-tracks/index" */))
const _f9716a56 = () => interopDefault(import('../src/pages/certificate.vue' /* webpackChunkName: "pages/certificate" */))
const _47849478 = () => interopDefault(import('../src/pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _874f2cce = () => interopDefault(import('../src/pages/community-guidelines.vue' /* webpackChunkName: "pages/community-guidelines" */))
const _0dc3cd9d = () => interopDefault(import('../src/pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _6a640a17 = () => interopDefault(import('../src/pages/course-certificate.vue' /* webpackChunkName: "pages/course-certificate" */))
const _8132650e = () => interopDefault(import('../src/pages/courses/index.vue' /* webpackChunkName: "pages/courses/index" */))
const _30f242d4 = () => interopDefault(import('../src/pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _7914f2de = () => interopDefault(import('../src/pages/fast-track-2024.vue' /* webpackChunkName: "pages/fast-track-2024" */))
const _fc566330 = () => interopDefault(import('../src/pages/flashcards/index.vue' /* webpackChunkName: "pages/flashcards/index" */))
const _6e428fa0 = () => interopDefault(import('../src/pages/forgotten-password.vue' /* webpackChunkName: "pages/forgotten-password" */))
const _216b0bcc = () => interopDefault(import('../src/pages/free-weeks-2023.vue' /* webpackChunkName: "pages/free-weeks-2023" */))
const _2179234d = () => interopDefault(import('../src/pages/free-weeks-2024.vue' /* webpackChunkName: "pages/free-weeks-2024" */))
const _7e2754f1 = () => interopDefault(import('../src/pages/future-proof-2024.vue' /* webpackChunkName: "pages/future-proof-2024" */))
const _feaef2ee = () => interopDefault(import('../src/pages/get-webinar-resources.vue' /* webpackChunkName: "pages/get-webinar-resources" */))
const _b2505692 = () => interopDefault(import('../src/pages/instructors/index.vue' /* webpackChunkName: "pages/instructors/index" */))
const _f2565514 = () => interopDefault(import('../src/pages/interview-simulator.vue' /* webpackChunkName: "pages/interview-simulator" */))
const _228a6596 = () => interopDefault(import('../src/pages/learn-data-and-ai.vue' /* webpackChunkName: "pages/learn-data-and-ai" */))
const _44d8afe2 = () => interopDefault(import('../src/pages/learn-finance.vue' /* webpackChunkName: "pages/learn-finance" */))
const _1c4cc0ed = () => interopDefault(import('../src/pages/live-trainings/index.vue' /* webpackChunkName: "pages/live-trainings/index" */))
const _3876d427 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0769de51 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _6919a846 = () => interopDefault(import('../src/pages/most-wanted-2024.vue' /* webpackChunkName: "pages/most-wanted-2024" */))
const _fff92aa6 = () => interopDefault(import('../src/pages/one-of-two-hundred-2024.vue' /* webpackChunkName: "pages/one-of-two-hundred-2024" */))
const _12224f4d = () => interopDefault(import('../src/pages/password.vue' /* webpackChunkName: "pages/password" */))
const _4386fc84 = () => interopDefault(import('../src/pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _416389a2 = () => interopDefault(import('../src/pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _2a0f0926 = () => interopDefault(import('../src/pages/qa-hub/index.vue' /* webpackChunkName: "pages/qa-hub/index" */))
const _99f9f0ca = () => interopDefault(import('../src/pages/question/index.vue' /* webpackChunkName: "pages/question/index" */))
const _8efd8778 = () => interopDefault(import('../src/pages/redirecting.vue' /* webpackChunkName: "pages/redirecting" */))
const _02a25313 = () => interopDefault(import('../src/pages/request-demo.vue' /* webpackChunkName: "pages/request-demo" */))
const _cf7c7790 = () => interopDefault(import('../src/pages/research-1001-data-scientists-2018-2020.vue' /* webpackChunkName: "pages/research-1001-data-scientists-2018-2020" */))
const _18f26c40 = () => interopDefault(import('../src/pages/scholarship.vue' /* webpackChunkName: "pages/scholarship" */))
const _22bd9dca = () => interopDefault(import('../src/pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _583bf9f3 = () => interopDefault(import('../src/pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _c7e7ed56 = () => interopDefault(import('../src/pages/something-better.vue' /* webpackChunkName: "pages/something-better" */))
const _11fd664a = () => interopDefault(import('../src/pages/start-your-journey-for-free.vue' /* webpackChunkName: "pages/start-your-journey-for-free" */))
const _f57f84c4 = () => interopDefault(import('../src/pages/start-your-journey-for-free-a.vue' /* webpackChunkName: "pages/start-your-journey-for-free-a" */))
const _d40739ec = () => interopDefault(import('../src/pages/success-stories/index.vue' /* webpackChunkName: "pages/success-stories/index" */))
const _656d8e60 = () => interopDefault(import('../src/pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _6a20d07c = () => interopDefault(import('../src/pages/upcoming-courses/index.vue' /* webpackChunkName: "pages/upcoming-courses/index" */))
const _48513675 = () => interopDefault(import('../src/pages/verify-certificate.vue' /* webpackChunkName: "pages/verify-certificate" */))
const _6c079d4c = () => interopDefault(import('../src/pages/warning.vue' /* webpackChunkName: "pages/warning" */))
const _fc945da6 = () => interopDefault(import('../src/pages/you-missed-it.vue' /* webpackChunkName: "pages/you-missed-it" */))
const _2f890885 = () => interopDefault(import('../src/pages/business/signup.vue' /* webpackChunkName: "pages/business/signup" */))
const _286d21fa = () => interopDefault(import('../src/pages/instructors/signup.vue' /* webpackChunkName: "pages/instructors/signup" */))
const _24f2098d = () => interopDefault(import('../src/pages/live-trainings/signup.vue' /* webpackChunkName: "pages/live-trainings/signup" */))
const _e2db9b08 = () => interopDefault(import('../src/pages/resources-center/offer.vue' /* webpackChunkName: "pages/resources-center/offer" */))
const _f0c68acc = () => interopDefault(import('../src/pages/support/search.vue' /* webpackChunkName: "pages/support/search" */))
const _469df7e3 = () => interopDefault(import('../src/pages/resources-center/exams/signup.vue' /* webpackChunkName: "pages/resources-center/exams/signup" */))
const _37acb840 = () => interopDefault(import('../src/pages/calculators/tables/_id.vue' /* webpackChunkName: "pages/calculators/tables/_id" */))
const _dac3f17c = () => interopDefault(import('../src/pages/resources-center/exams/_id/index.vue' /* webpackChunkName: "pages/resources-center/exams/_id/index" */))
const _d2e9c844 = () => interopDefault(import('../src/pages/resources-center/exams/_id/finalize.vue' /* webpackChunkName: "pages/resources-center/exams/_id/finalize" */))
const _82cb1436 = () => interopDefault(import('../src/pages/resources-center/exams/_id/instructions.vue' /* webpackChunkName: "pages/resources-center/exams/_id/instructions" */))
const _43d9106c = () => interopDefault(import('../src/pages/calculators/_name.vue' /* webpackChunkName: "pages/calculators/_name" */))
const _2632a8d6 = () => interopDefault(import('../src/pages/career-tracks/_track.vue' /* webpackChunkName: "pages/career-tracks/_track" */))
const _1a5d2fe4 = () => interopDefault(import('../src/pages/courses/_course/index.vue' /* webpackChunkName: "pages/courses/_course/index" */))
const _1ed23d4f = () => interopDefault(import('../src/pages/f/_id.vue' /* webpackChunkName: "pages/f/_id" */))
const _cac748ba = () => interopDefault(import('../src/pages/flashcards/_slug/index.vue' /* webpackChunkName: "pages/flashcards/_slug/index" */))
const _1a961fa5 = () => interopDefault(import('../src/pages/live-trainings/_slug.vue' /* webpackChunkName: "pages/live-trainings/_slug" */))
const _0f828a31 = () => interopDefault(import('../src/pages/preview/_id.vue' /* webpackChunkName: "pages/preview/_id" */))
const _44d0cc32 = () => interopDefault(import('../src/pages/projects/_slug.vue' /* webpackChunkName: "pages/projects/_slug" */))
const _75e85228 = () => interopDefault(import('../src/pages/qa-hub/_article.vue' /* webpackChunkName: "pages/qa-hub/_article" */))
const _56b9da0e = () => interopDefault(import('../src/pages/question/_question.vue' /* webpackChunkName: "pages/question/_question" */))
const _16a29b9e = () => interopDefault(import('../src/pages/special-offer/_id.vue' /* webpackChunkName: "pages/special-offer/_id" */))
const _167b9ff2 = () => interopDefault(import('../src/pages/success-stories/_id.vue' /* webpackChunkName: "pages/success-stories/_id" */))
const _0721a190 = () => interopDefault(import('../src/pages/support/_id.vue' /* webpackChunkName: "pages/support/_id" */))
const _d10cb2f2 = () => interopDefault(import('../src/pages/flashcards/_slug/checkpoint.vue' /* webpackChunkName: "pages/flashcards/_slug/checkpoint" */))
const _34dcd287 = () => interopDefault(import('../src/pages/flashcards/_slug/results.vue' /* webpackChunkName: "pages/flashcards/_slug/results" */))
const _1e022bd7 = () => interopDefault(import('../src/pages/flashcards/_slug/review.vue' /* webpackChunkName: "pages/flashcards/_slug/review" */))
const _5632ed1a = () => interopDefault(import('../src/pages/flashcards/_slug/study.vue' /* webpackChunkName: "pages/flashcards/_slug/study" */))
const _77cc99d1 = () => interopDefault(import('../src/pages/flashcards/_slug/test.vue' /* webpackChunkName: "pages/flashcards/_slug/test" */))
const _5bd8779d = () => interopDefault(import('../src/pages/courses/_course/_lecture.vue' /* webpackChunkName: "pages/courses/_course/_lecture" */))
const _6ecf3110 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _957e85c6 = () => interopDefault(import('../src/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _9416efe0,
    name: "about-us"
  }, {
    path: "/authenticate",
    component: _53ce9cf2,
    name: "authenticate"
  }, {
    path: "/black-friday-2024",
    component: _e02e8fc4,
    name: "black-friday-2024"
  }, {
    path: "/business",
    component: _03de02f5,
    name: "business"
  }, {
    path: "/calculators",
    component: _3ad77932,
    name: "calculators"
  }, {
    path: "/career-track-certificate",
    component: _c588fa90,
    name: "career-track-certificate"
  }, {
    path: "/career-tracks",
    component: _6b869790,
    name: "career-tracks"
  }, {
    path: "/certificate",
    component: _f9716a56,
    name: "certificate"
  }, {
    path: "/checkout",
    component: _47849478,
    name: "checkout"
  }, {
    path: "/community-guidelines",
    component: _874f2cce,
    name: "community-guidelines"
  }, {
    path: "/contact-us",
    component: _0dc3cd9d,
    name: "contact-us"
  }, {
    path: "/course-certificate",
    component: _6a640a17,
    name: "course-certificate"
  }, {
    path: "/courses",
    component: _8132650e,
    name: "courses"
  }, {
    path: "/faq",
    component: _30f242d4,
    name: "faq"
  }, {
    path: "/fast-track-2024",
    component: _7914f2de,
    name: "fast-track-2024"
  }, {
    path: "/flashcards",
    component: _fc566330,
    name: "flashcards"
  }, {
    path: "/forgotten-password",
    component: _6e428fa0,
    name: "forgotten-password"
  }, {
    path: "/free-weeks-2023",
    component: _216b0bcc,
    name: "free-weeks-2023"
  }, {
    path: "/free-weeks-2024",
    component: _2179234d,
    name: "free-weeks-2024"
  }, {
    path: "/future-proof-2024",
    component: _7e2754f1,
    name: "future-proof-2024"
  }, {
    path: "/get-webinar-resources",
    component: _feaef2ee,
    name: "get-webinar-resources"
  }, {
    path: "/instructors",
    component: _b2505692,
    name: "instructors"
  }, {
    path: "/interview-simulator",
    component: _f2565514,
    name: "interview-simulator"
  }, {
    path: "/learn-data-and-ai",
    component: _228a6596,
    name: "learn-data-and-ai"
  }, {
    path: "/learn-finance",
    component: _44d8afe2,
    name: "learn-finance"
  }, {
    path: "/live-trainings",
    component: _1c4cc0ed,
    name: "live-trainings"
  }, {
    path: "/login",
    component: _3876d427,
    name: "login"
  }, {
    path: "/maintenance",
    component: _0769de51,
    name: "maintenance"
  }, {
    path: "/most-wanted-2024",
    component: _6919a846,
    name: "most-wanted-2024"
  }, {
    path: "/one-of-two-hundred-2024",
    component: _fff92aa6,
    name: "one-of-two-hundred-2024"
  }, {
    path: "/password",
    component: _12224f4d,
    name: "password"
  }, {
    path: "/pricing",
    component: _4386fc84,
    name: "pricing"
  }, {
    path: "/projects",
    component: _416389a2,
    name: "projects"
  }, {
    path: "/qa-hub",
    component: _2a0f0926,
    name: "qa-hub"
  }, {
    path: "/question",
    component: _99f9f0ca,
    name: "question"
  }, {
    path: "/redirecting",
    component: _8efd8778,
    name: "redirecting"
  }, {
    path: "/request-demo",
    component: _02a25313,
    name: "request-demo"
  }, {
    path: "/research-1001-data-scientists-2018-2020",
    component: _cf7c7790,
    name: "research-1001-data-scientists-2018-2020"
  }, {
    path: "/scholarship",
    component: _18f26c40,
    name: "scholarship"
  }, {
    path: "/signup",
    component: _22bd9dca,
    name: "signup"
  }, {
    path: "/sitemap",
    component: _583bf9f3,
    name: "sitemap"
  }, {
    path: "/something-better",
    component: _c7e7ed56,
    name: "something-better"
  }, {
    path: "/start-your-journey-for-free",
    component: _11fd664a,
    name: "start-your-journey-for-free"
  }, {
    path: "/start-your-journey-for-free-a",
    component: _f57f84c4,
    name: "start-your-journey-for-free-a"
  }, {
    path: "/success-stories",
    component: _d40739ec,
    name: "success-stories"
  }, {
    path: "/support",
    component: _656d8e60,
    name: "support"
  }, {
    path: "/upcoming-courses",
    component: _6a20d07c,
    name: "upcoming-courses"
  }, {
    path: "/verify-certificate",
    component: _48513675,
    name: "verify-certificate"
  }, {
    path: "/warning",
    component: _6c079d4c,
    name: "warning"
  }, {
    path: "/you-missed-it",
    component: _fc945da6,
    name: "you-missed-it"
  }, {
    path: "/business/signup",
    component: _2f890885,
    name: "business-signup"
  }, {
    path: "/instructors/signup",
    component: _286d21fa,
    name: "instructors-signup"
  }, {
    path: "/live-trainings/signup",
    component: _24f2098d,
    name: "live-trainings-signup"
  }, {
    path: "/resources-center/offer",
    component: _e2db9b08,
    name: "resources-center-offer"
  }, {
    path: "/support/search",
    component: _f0c68acc,
    name: "support-search"
  }, {
    path: "/resources-center/exams/signup",
    component: _469df7e3,
    name: "resources-center-exams-signup"
  }, {
    path: "/calculators/tables/:id?",
    component: _37acb840,
    name: "calculators-tables-id"
  }, {
    path: "/resources-center/exams/:id",
    component: _dac3f17c,
    name: "resources-center-exams-id"
  }, {
    path: "/resources-center/exams/:id?/finalize",
    component: _d2e9c844,
    name: "resources-center-exams-id-finalize"
  }, {
    path: "/resources-center/exams/:id?/instructions",
    component: _82cb1436,
    name: "resources-center-exams-id-instructions"
  }, {
    path: "/calculators/:name",
    component: _43d9106c,
    name: "calculators-name"
  }, {
    path: "/career-tracks/:track?",
    component: _2632a8d6,
    name: "career-tracks-track"
  }, {
    path: "/courses/:course",
    component: _1a5d2fe4,
    name: "courses-course"
  }, {
    path: "/f/:id?",
    component: _1ed23d4f,
    name: "f-id"
  }, {
    path: "/flashcards/:slug",
    component: _cac748ba,
    name: "flashcards-slug"
  }, {
    path: "/live-trainings/:slug?",
    component: _1a961fa5,
    name: "live-trainings-slug"
  }, {
    path: "/preview/:id?",
    component: _0f828a31,
    name: "preview-id"
  }, {
    path: "/projects/:slug",
    component: _44d0cc32,
    name: "projects-slug"
  }, {
    path: "/qa-hub/:article?",
    component: _75e85228,
    name: "qa-hub-article"
  }, {
    path: "/question/:question",
    component: _56b9da0e,
    name: "question-question"
  }, {
    path: "/special-offer/:id?",
    component: _16a29b9e,
    name: "special-offer-id"
  }, {
    path: "/success-stories/:id?",
    component: _167b9ff2,
    name: "success-stories-id"
  }, {
    path: "/support/:id",
    component: _0721a190,
    name: "support-id"
  }, {
    path: "/flashcards/:slug/checkpoint",
    component: _d10cb2f2,
    name: "flashcards-slug-checkpoint"
  }, {
    path: "/flashcards/:slug/results",
    component: _34dcd287,
    name: "flashcards-slug-results"
  }, {
    path: "/flashcards/:slug/review",
    component: _1e022bd7,
    name: "flashcards-slug-review"
  }, {
    path: "/flashcards/:slug/study",
    component: _5632ed1a,
    name: "flashcards-slug-study"
  }, {
    path: "/flashcards/:slug/test",
    component: _77cc99d1,
    name: "flashcards-slug-test"
  }, {
    path: "/courses/:course/:lecture",
    component: _5bd8779d,
    name: "courses-course-lecture"
  }, {
    path: "/",
    component: _6ecf3110,
    name: "index"
  }, {
    path: "/*",
    component: _957e85c6,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
